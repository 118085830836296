import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import BlogSinglePost from "../components/Blog/BlogSinglePost";

const Post = ({ data }) => {
   return (
      <Layout headerStyle="standard" headerLinkColor="dark" headerHasBorder={false}>
         <SearchEngineOptimization
            title="What Are the Different Types of Fiberglass? | Painted Rhino"
            description="Fiberglass is non-corrosive and resistant to mold, mildew, and salt spray, making it ideal for many applications. Learn more about the two types of fiberglass."
            // openGraphImage={data.openGraphImage.publicURL}
            // twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
         />

         <BlogSinglePost
            title="What Are the Different Types of Fiberglass?"
            date="February 8, 2022"
            author="Painted Rhino"
            category=""
            featuredImage={data.featuredImage.childImageSharp.fluid}
            content={
               <>
                  <p>
                     Fiberglass was first introduced as a material used in airplanes and boats and has also gained popularity as a valued material
                     frequently used in building construction, automobiles, bridges, and more. It’s a strong and lightweight material consisting of
                     glass fibers that are flattened, woven, or arranged randomly. There are two main fiberglass types. The first is created by mixing
                     glass fibers with resin. The second uses spun natural rock such as quartz to form glass wool. Both types are non-corrosive and
                     resistant to mold, mildew, and salt spray, making them ideal materials for many applications.
                  </p>

                  <h2>Fiberglass Types by Form</h2>
                  <p>Fiberglass is available in several forms to suit a variety of needs.</p>

                  <h3>Fiberglass Tape</h3>
                  <p>
                     This tape is an excellent material to reinforce joints or seal seams. It is also good for heat insulation. Fiberglass tape is
                     made of long, continuous fibers woven together to create various widths and lengths. It’s available in a variety of colors to
                     match your project.
                  </p>

                  <h3>Fiberglass Cloth</h3>
                  <p>
                     Fiberglass cloth has similar uses as tape. It’s also great for heal sealing, and wrapping electric wires. Other uses include
                     architectural glass and home construction. Fiberglass cloth is available in various colors and hardness.
                  </p>

                  <h3>Fiberglass Rope</h3>
                  <p>
                     Long fibers are woven into a tight and durable mesh form fiberglass rope. The rope contains UV protection to resist pealing and
                     comes in several widths, lengths, and colors. Fiberglass rope is often used to support concrete and masonry projects.
                  </p>

                  <h2>Types of Fiberglass by Property</h2>
                  <p>There are many various methods and materials to create fiberglass that have different properties and uses:</p>

                  <ul>
                     <li>A Glass: A Glass uses fine, thin strands of glass fibers created with molten glass.</li>
                     <li>
                        B Glass: This type is also called rock wool and is made from quartz, feldspar, and other natural rocks. B Glass fiber is less
                        costly than A Glass fiber.
                     </li>
                     <li>C Glass: A synthetic fiber made with alumina oxide and often used to strengthen plastic.</li>
                     <li>D Glass: Woven glass fibers that receive a resin coating and are baked to become durable and elastic.</li>
                     <li>E Glass: This type is the most common and is an effective heat insulator. E Glass fiber is made from spun molten glass.</li>
                     <li>ECR Glass: A synthetic form of fiberglass used mainly in windows and doors.</li>
                     <li>AE Glass: Another synthetic fiberglass, also used to create windows and doors.</li>
                     <li>S-Glass: A synthetic fiber that is stronger and even more effective heat insulator than E Glass.</li>
                     <li>R-Glass: This is used to create bulletproof glass.</li>
                  </ul>

                  <p>The form and property you require in your fiberglass depends on the desired application for the product.</p>

                  <p>
                     Since 1997, Painted Rhino has been <a href="/fiberglass-composite-parts-manufacturing/">manufacturing fiberglass products</a> for
                     a wide variety of industries. We offer fast service and customized options for your project, no matter how complex. Let us turn
                     your design into the exact fiberglass components you need.
                  </p>
               </>
            }
         />
      </Layout>
   );
};

export const query = graphql`
   {
      openGraphImage: file(relativePath: { eq: "open-graph/facebook/FB_Global.jpg" }) {
         publicURL
      }
      twitterOpenGraphImage: file(relativePath: { eq: "open-graph/twitter/Twitter_Global.jpg" }) {
         publicURL
      }
      featuredImage: file(relativePath: { eq: "blog/What are the different types of fiberglass.jpg" }) {
         childImageSharp {
            fluid(maxWidth: 2500) {
               ...GatsbyImageSharpFluid_withWebp
            }
         }
      }
   }
`;

export default Post;
